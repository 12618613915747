import { graphql } from 'gatsby';
import React from 'react';

import { CookieConsent } from '../components/cookie-consent';
import { ServiceDataQuery } from '../types/graphql';
import { Language } from '../types/graphql-extended';
import makeLazy from '../util/make-lazy';

const ServiceApp = makeLazy(() => import('../components/service/app'));

interface PageProps {
  data: ServiceDataQuery;
  pageContext: {
    alternateLanguages: Language[];
    lang: string;
  };
  pageResources: {
    page: {
      path: string;
    };
  };
}

const Service: React.FC<PageProps> = ({
  data: {
    defaultCookieConsent,
    defaultFooter,
    defaultHeader,
    cookieConsent,
    footer,
    header,
  },
  pageContext,
  pageResources,
}) => (
  <>
    {typeof window !== 'undefined' && (
      <ServiceApp
        alternateLanguages={pageContext.alternateLanguages}
        basepath={pageResources?.page?.path || ''}
        lang={pageContext.lang}
        headerData={(header || defaultHeader)!}
        footerData={(footer || defaultFooter)!}
      />
    )}

    <CookieConsent
      content={(cookieConsent || defaultCookieConsent)!}
      lang={pageContext.lang}
    />
  </>
);

export default Service;

export const query = graphql`
  query ServiceData($lang: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    header: markdownRemark(
      fields: { collection: { eq: "header" }, lang: { eq: $lang } }
    ) {
      ...Header
    }
    footer: markdownRemark(
      fields: { collection: { eq: "footer" }, lang: { eq: $lang } }
    ) {
      ...Footer
    }
    cookieConsent: markdownRemark(
      fields: { collection: { eq: "cookie-consent" }, lang: { eq: $lang } }
    ) {
      ...CookieConsent
    }
    defaultHeader: markdownRemark(
      fields: { collection: { eq: "header" }, lang: { eq: "en" } }
    ) {
      ...Header
    }
    defaultFooter: markdownRemark(
      fields: { collection: { eq: "footer" }, lang: { eq: "en" } }
    ) {
      ...Footer
    }
    defaultCookieConsent: markdownRemark(
      fields: { collection: { eq: "cookie-consent" }, lang: { eq: "en" } }
    ) {
      ...CookieConsent
    }
  }
`;
